import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { trackingGA } from "@merchx-v2/helpers";
import { graphql, Link } from "gatsby";
import { useSiteMetadata } from "hooks/use-site-metadata";
import { Button, Footer, Header, PoliciesMenu, SEO } from "components";
import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import * as styles from "./RefundPolicy.module.scss";

const RefundPolicy = ({ menu, resetMenuFilters, setMenuCollection, data }) => {
  const { shared } = useSiteMetadata();

  const supportEmail = shared.SUPPORT_EMAIL;

  const [showButtonClass, setShowButtonClass] = useState("buttonToTopHidden");

  const onScroll = (e) => {
    if (window.pageYOffset > 100) {
      setShowButtonClass("buttonToTop");
    } else {
      setShowButtonClass("buttonToTopHidden");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    window.scrollTo(0, 0);
    trackingGA();

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  const { SEO_TITLE, SEO_DESCRIPTION, SEO_KEYWORDS } =
    data?.markdownRemark?.frontmatter || {};

  return (
    <div className={styles.container}>
      <SEO
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        keywords={SEO_KEYWORDS}
        pathname="/refund-policy"
      />
      <Header
        menu={menu}
        onCollectionClicked={handleCollectionClicked}
        onResetCollection={handleReset}
      />
      <div className={styles.content}>
        <PoliciesMenu page="refund" />
        <div className={styles.refundPart}>
          <p className={styles.breadcrumbs}>Return & Refund Policy</p>
          <div className={styles.infoItem1}>
            <p className={styles.title}>Returns and Refunds at Fan Made Fits</p>
            <div className={styles.supportButton}>
              <Link className={styles.linkButton} to="/support">
                <Button value="Contact Support" className="buttonBlue" />
              </Link>
            </div>
            <p className={styles.infoText}>
              First off, let us start with saying customer experience and
              happiness is one of our primary missions here at Fan Made Fits. We
              hope we can make your refund or return experience as hassle-free
              as possible.
            </p>
          </div>

          <div className={styles.infoItem}>
            <p className={styles.title}>Return and Refunds</p>
            <p className={styles.infoText}>
              Refunds and returns are typically approved on a case-by-case
              basis. Please contact{" "}
              <a className={styles.linkText} href={`mailto:${supportEmail}`}>
                {supportEmail}
              </a>{" "}
              in order to request for a refund or return.
            </p>
            <p className={styles.infoText}>
              We typically ask that you request a refund/return request within
              30 days of your order.
            </p>
            <p className={styles.infoText}>
              To initiate a refund or return, please email us at{" "}
              <a className={styles.linkText} href={`mailto:${supportEmail}`}>
                {supportEmail}
              </a>
              .
            </p>
            <p className={styles.infoText}>
              Once your return is received, we will send you an email to notify
              you that we have received your returned item. We will also notify
              you of the approval or rejection of your refund.
            </p>
            <p className={styles.infoText}>
              Once your return is receivied, credit will automatically be
              applied to your credit card or original method of payment. How
              long it takes to process a refund is largely out of our control
              (and more with the banks). Typically we see most refunds process
              within a 3-5 business days.
            </p>
          </div>

          <div className={styles.infoItem}>
            <p className={styles.title}>Product condition</p>

            <p className={styles.infoText}>
              In order to return an item, the item must be in a new
              condition/unworn.
            </p>
          </div>

          <div className={styles.infoItem}>
            <p className={styles.title}>
              Late or missing refunds (if applicable)
            </p>

            <p className={styles.infoText}>
              If you haven’t received a refund yet, first check your bank
              account again.
            </p>
            <p className={styles.infoText}>
              Again, most refunds are typically processed by your bank or credit
              card company within 3-5 business days. However, we’ve seen some
              instances take up to 14 days. Please bear with us.
            </p>
            <p className={styles.infoText}>
              If you still have not received your refund yet past this
              timeframe, please contact us at{" "}
              <a className={styles.linkText} href={`mailto:${supportEmail}`}>
                {supportEmail}
              </a>
              .
            </p>
          </div>

          <div className={styles.infoItem}>
            <p className={styles.title}>Exchanges (if applicable)</p>
            <p className={styles.infoText}>
              We only replace items if they are defective or damaged. However,
              if you need to exchange an item for a different size, send us an
              email at{" "}
              <a className={styles.linkText} href={`mailto:${supportEmail}`}>
                {supportEmail}
              </a>
              .
            </p>
            <p className={styles.infoText}>
              If the item wasn’t marked as a gift when purchased, or the gift
              giver had the order shipped to themselves to give to you later, we
              will send a refund to the gift giver and he will find out about
              your return.
            </p>
          </div>

          <div className={styles.infoItem}>
            <p className={styles.title}>Return Shipping and Procedurre</p>
            <p className={styles.infoText}>
              Before returning your product, please email us first at{" "}
              <a className={styles.linkText} href={`mailto:${supportEmail}`}>
                {supportEmail}
              </a>
              .
            </p>
            <p className={styles.infoText}>
              In most cases, we ask the customer pay for the cost of shipping
              youre return. Shipping costs are non-refundable. If you receive a
              refund, the cost of return shipping will be deducted from your
              refund.
            </p>
            <p className={styles.infoText}>
              Depending on where you live, the time it may take for your
              exchanged product to reach you, may vary. If you are shipping an
              item over $75, you should consider using a trackable shipping
              service or purchasing shipping insurance to ensure proper
              delivery.
            </p>
          </div>
        </div>

        <button
          className={styles[showButtonClass]}
          onClick={(e) => window.scroll(0, 0)}
        >
          Back to top
        </button>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
});
const mapDispatchToProps = {
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundPolicy);

export const query = graphql`
  query RefundPolicyPage {
    markdownRemark(fields: { slug: { eq: "/refund-policy/" } }) {
      frontmatter {
        SEO_TITLE
        SEO_DESCRIPTION
        SEO_KEYWORDS
      }
    }
  }
`;
