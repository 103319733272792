// extracted by mini-css-extract-plugin
export var container = "RefundPolicy-module--container--3-oFn";
export var content = "RefundPolicy-module--content--1dWzW";
export var refundPart = "RefundPolicy-module--refundPart--2pIzz";
export var breadcrumbs = "RefundPolicy-module--breadcrumbs--3dnxA";
export var infoItem1 = "RefundPolicy-module--infoItem1--2JsQQ";
export var infoItem = "RefundPolicy-module--infoItem--3nwyI";
export var title = "RefundPolicy-module--title--DhAVn";
export var supportButton = "RefundPolicy-module--supportButton--2XaRv";
export var infoText = "RefundPolicy-module--infoText--eKfGO";
export var linkText = "RefundPolicy-module--linkText--1-p27";
export var linkButton = "RefundPolicy-module--linkButton--2THd1";
export var buttonToTop = "RefundPolicy-module--buttonToTop--3noBs";
export var buttonToTopHidden = "RefundPolicy-module--buttonToTopHidden--durPC";
export var footerContainer = "RefundPolicy-module--footerContainer--1pX-k";